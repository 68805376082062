import React from 'react'
import get from 'lodash/get'
import { Helmet } from 'react-helmet';

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let language = 'en';
    if (navigator.languages !== undefined)  {
      language = navigator.languages[0]; 
    } else {
      language = navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
    }
    const defaultLocale = language.substring(0, 2).toLowerCase() === 'ko' ? 'ko' : 'en';
    window.location.href = `/${defaultLocale}`;
  }

  render() {
    const [{ node: siteContents }] = get(this, 'props.data.allContentfulSiteContents.edges')
    return (
      <>
        <Helmet>
					<meta name="viewport" content="width=device-width,height=device-height,initial-scale=1,maximum-scale=1,user-scalable=no,viewport-fit=cover" />
					<title>Common Computer</title>
					<meta name="description" content="Common Computer builds a transparent network to connect computers in the world." />
					<meta property="og:site_name" content="comcom" />
					<meta property="og:type" content="website" />
					<meta property="og:url" content="https://comcom.ai" />
					<meta property="og:title" content={siteContents.ogTitle} />
					<meta property="og:description" content={siteContents.ogDescription.ogDescription} />
					<meta property="og:image" content={siteContents.ogImage.file.url} />
        </Helmet>
      </>
    )
  }
}

export const pageQuery = graphql`
  query IndexQuery {
    allContentfulSiteContents(filter: {node_locale: {eq: "ko-KR"}}) {
      edges {
        node {
          ogTitle
          ogDescription {
            ogDescription
          }
          ogImage {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage
